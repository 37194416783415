const iPhoneProductTemplate = {
  features: {
    material: "TPU + Polycarbonate Plastic",
    finish: "Glossy Finish",
    glass: "Premium 9H Tempered Glass",
    printing_technique: "Sublimation print",
    case_type: "Glass/Bumper",
    protection: "Full 360° coverage",
    quantity: "1 Unit",
  },
  care_instructions: [
    "Clean with a soft, lint-free cloth to remove dust.",
    "Do not expose to extreme temperatures.",
    "Avoid exposing to excessive moisture or submersion in water.",
  ],
  description: `Give your phone the spotlight it deserves with our glass case—the ultimate showstopper, designed by you. "Glassy is the new glossy"? Yeah, we coined that just for you. Every time you take out your phone, its sleek design and dazzling style, custom-made by you, will do the talking. Still clinging to your old phone? No problem—give it a fresh, fun makeover with our latest glass case. Featuring a glossy 9H tempered glass back, this case is lightweight, sleek, and shockproof against everyday bumps. The rubberized TPU sides offer a superior grip and protect your phone from all angles. And yes, it’s wireless charger-friendly, so you don't have to choose between style and convenience. We’re the OGs of phone cases, and this one’s truly a glass apart (pun totally intended).`,
};

function createiPhoneProduct(id, handle) {
  return {
    id: id,
    title: "Customised iPhone Cover",
    handle: handle,
    name: "iphone_cases",
    ...iPhoneProductTemplate,
  };
}

const iphoneProducts = [
  createiPhoneProduct(
    "prod_pt035_cu0002",
    "customised-iphone-14-pro-max-cover"
  ),
  createiPhoneProduct("prod_pt034_cu0002", "customised-iphone-14-pro-cover"),
  createiPhoneProduct("prod_pt033_cu0002", "customised-iphone-14-plus-cover"),
  createiPhoneProduct("prod_pt032_cu0002", "customised-iphone-14-cover"),
  createiPhoneProduct(
    "prod_pt031_cu0002",
    "customised-iphone-13-pro-max-cover"
  ),
  createiPhoneProduct("prod_pt030_cu0002", "customised-iphone-13-pro-cover"),
  createiPhoneProduct("prod_pt029_cu0002", "customised-iphone-13-mini-cover"),
  createiPhoneProduct("prod_pt028_cu0002", "customised-iphone-13-cover"),
  createiPhoneProduct(
    "prod_pt027_cu0002",
    "customised-iphone-12-pro-max-cover"
  ),
  createiPhoneProduct("prod_pt026_cu0002", "customised-iphone-12-pro-cover"),
  createiPhoneProduct("prod_pt025_cu0002", "customised-iphone-12-mini-cover"),
  createiPhoneProduct("prod_pt024_cu0002", "customised-iphone-12-cover"),
  createiPhoneProduct("prod_pt107_cu0002", "customised-iphone-15-pro-cover"),
  createiPhoneProduct(
    "prod_pt108_cu0002",
    "customised-iphone-15-pro-max-cover"
  ),
  createiPhoneProduct("prod_pt106_cu0002", "customised-iphone-15-plus-cover"),
  createiPhoneProduct("prod_pt105_cu0002", "customised-iphone-15-cover"),

  createiPhoneProduct("prod_pt114_cu0002", "customised-iphone-16-pro-cover"),
  createiPhoneProduct(
    "prod_pt113_cu0002",
    "customised-iphone-16-pro-max-cover"
  ),
  createiPhoneProduct("prod_pt112_cu0002", "customised-iphone-16-plus-cover"), 
  createiPhoneProduct("prod_pt111_cu0002", "customised-iphone-16-cover"),
];


/**
 * @typedef {'customised-hoodie' | 'customised-sweatshirt' | 'customised-casual-tote-bag' | 'customised-t-shirt' | 'customised-oversized-t-shirt' | 'customised-metal-poster' | 'customised-product-puzzle' | 'customised-iphone-14-pro-max-cover' | 'customised-iphone-14-pro-cover' | 'customised-iphone-13-pro-max-cover' | 'customised-iphone-13-pro-cover' | 'customised-iphone-13-cover' | 'customised-iphone-13-mini-cover' | 'customised-iphone-12-pro-max-cover' | 'customised-iphone-12-pro-cover' | 'customised-iphone-12-cover' | 'customised-iphone-12-mini-cover' | 'customised-iphone-15-pro-cover' | 'customised-iphone-15-pro-max-cover' | 'customised-iphone-15-plus-cover' | 'customised-iphone-15-cover' | 'customised-iphone-14-cover' | 'customised-iphone-14-plus-cover'| 'customised-iphone-16-plus-cover' | 'customised-iphone-16-cover' | 'customised-iphone-16-pro-max-cover' | 'customised-iphone-16-pro-cover'} Handle
 */

/**
 * @typedef {Object} Features
 * @property {string} material
 * @property {string} quantity
 * @property {string} [printing_technique]
 * @property {string} [printing_type]
 * @property {string} [size]
 * @property {string} [finish]
 * @property {string} [case_type]
 */

/**
 * @typedef {Object} Product
 * @property {string} id
 * @property {string} title
 * @property {Handle} handle
 * @property {string} name
 * @property {Features} features
 * @property {string[]} care_instructions
 * @property {string} description
 */

/**
 * @type {Product[]}
 */
export const productData = [
  {
    id: "prod_pt055_cu0002",
    title: "Customised Mens Hoodie",
    handle: "customised-hoodie",
    name: "hoodie",
    features: {
      material: "280 GSM, Bio-wash, Pre-shrunk, 100% Pure Cotton",
      quantity: "1 Unit",
      printing_technique: "Digital Printing Front Side",
      neckline: "Hooded Neck",
      fit: "Regular Fit",
      sleeves: "Long Sleeves",
      country_of_origin: "India",
    },
    care_instructions: [
      "Machine Wash Cold",
      "Do Not Bleach",
      "Do Not Dry Clean",
      "Use Low Iron",
      "Wash Separately",
    ],
    description: `Say hello to your new hoodie obsession—crafted entirely by you! This isn’t just some run-of-the-mill Black Hoodie; it’s your brainchild wrapped in a killer 280 GSM premium cotton fleece. Warmth, comfort, and style? Nailed it. Your design isn’t just printed—it’s practically brought to life with cutting-edge digital printing that makes every detail sharper than your comebacks and colours so vibrant they’ll make your followers double-tap without thinking. This hoodie isn’t just something you wear; it’s a walking, talking, conversation starter wherever you go. And the best part? You’re the genius behind it. This one-of-a-kind hoodie is 100% your creation, making it as unique as your late-night snack combos. Wear it loud, wear it proud—because no one else has one like it.`,
  },
  {
    id: "prod_pt057_cu0002",
    title: "Customised Mens Sweatshirt",
    handle: "customised-sweatshirt",
    name: "sweatshirt",
    features: {
      material: "300 GSM, 100% Cotton Fleece",
      quantity: "1 Unit",
      printing_technique: "Digital Printing Front Side",
      neckline: "Round Neck",
      fit: "Regular Fit",
      sleeves: "Long Sleeves",
      country_of_origin: "India",
    },
    care_instructions: [
      "Machine Wash Cold",
      "Do Not Bleach",
      "Do Not Dry Clean",
      "Use Low Iron",
      "Wash Separately",
    ],
    description: `Make your mark with this black sweatshirt, designed to stand out and keep you cozy. Made from 300 GSM of premium 100% cotton fleece, it’s not just any sweatshirt—it’s your personal go-to sweatshirt, blending top-tier quality with unbeatable comfort. This isn’t just about looking good; it’s about feeling unstoppable in a fit that’s tailored to perfection. Whether you're dodging life's curveballs or just the morning alarm, this black beauty has got your back—literally. Wear it, own it, and let them wonder where you got it. Spoiler: It’s a one-of-a-kind creation from your genius mind.`,
  },
  {
    id: "prod_pt013_cu0002",
    title: "Customised Casual Tote Bag",
    handle: "customised-casual-tote-bag",
    name: "tote_bag",
    features: {
      material: "Black Canvas with Tan Leather Handle",
      size: "14.5 inch x 17 inch",
      handle_size: "12 inches",
      printing_technique: "One Side Digital Printing",
    },
    care_instructions: [
      "Machine Wash Cold",
      "Do Not Bleach",
      "Do Not Dry Clean",
      "Use Low Iron",
      "Wash Separately",
    ],
    description: `Meet your new go-to bag: the black canvas tote with a tan leather handle that’s as awesome as you are. This isn’t just a tote—it’s your new sidekick. With a magnetic button closure that says, “I’m secure, but not uptight,” and a sneaky zip pouch inside for your secret stash, this bag’s got it all. Style? Check. Function? Double check. And let’s talk about those designs—each one more jaw-droppingly cool than the last. Whether you’re hauling books, groceries, or just making a statement, this tote does it with flair. Slim, sleek, and seriously stylish, it’s the bag that’ll make you wonder why you ever settled for anything less.`,
  },
  {
    id: "prod_pt058_cu0002",
    title: "Customised Mens T-Shirt",
    handle: "customised-t-shirt",
    name: "t_shirt",
    features: {
      material: "180 GSM, Bio-wash, Pre-shrunk, Pure Cotton",
      quantity: "1 Unit",
      printing_technique: "Digital Printing Front Side",
      neckline: "Crewneck",
      fit: "Regular Fit",
      sleeves: "Short Sleeves",
      country_of_origin: "India",
    },
    care_instructions: [
      "Machine Wash Cold",
      "Do Not Bleach",
      "Do Not Dry Clean",
      "Use Low Iron",
    ],
    description: `Meet your new favourite black tee and trust us—this one’s got bragging rights because it’s your creation! Designed entirely from your prompt, this isn’t just another t-shirt; it’s your masterpiece wrapped in 180 GSM, bio-wash, pre-shrunk, pure cotton goodness. Comfort? Check. Style? Double check. With our cutting-edge digital printing tech, your design doesn’t just pop—it practically leaps off the fabric with razor-sharp details and colours so vibrant they’ll make your Instagram filter jealous. This isn’t just a tee; it’s a walking, talking conversation starter. And here’s the kicker: You’re the official designer of this bad boy, making it a one-of-a-kind piece that’s as unique as you are. Wear it with pride, knowing you’ve created something truly exceptional—like a boss.`,
  },
  {
    id: "prod_pt056_cu0002",
    title: "Customised Mens Oversized T-Shirt",
    handle: "customised-oversized-t-shirt",
    name: "over_sized_t_shirt",
    features: {
      material: "220 GSM, Bio-wash, Pre-shrunk, 100% Pure Cotton",
      quantity: "1 Unit",
      printing_technique: "Digital Printing Back Side",
      neckline: "Round Neck",
      fit: "Oversized Fit",
      sleeves: "Short Sleeves",
      country_of_origin: "India",
    },
    care_instructions: [
      "Machine Wash Cold",
      "Do Not Bleach",
      "Do Not Dry Clean",
      "Use Low Iron",
    ],
    description: `Meet your new oversized obsession—crafted entirely by you! This tee is wrapped in 220 GSM of pure comfort that’s so soft; you’ll want to live in it. With a relaxed fit that screams ‘I woke up like this,’ this tee is all about laid-back vibes and effortless cool. Your design in authentic street style is printed at the back of the t-shirt where it belongs—because real swag doesn’t need to show off. Thanks to our killer digital printing, every detail is sharp, and the colours are so vibrant they might just outshine your personality. And the best part? This one-of-a-kind oversized tee is 100% you, making it bold and unforgettable. Whether you’re chilling at home or stepping out with friends, wear it with pride—because no one else has a tee as epic as yours.`,
  },
  {
    id: "prod_pt109_cu0002",
    title: "Customised Metal Poster",
    handle: "customised-metal-poster",
    name: "poster",
    features: {
      size: "A3",
      material: "Metal",
      finish: "Matte",
      printing_type: "Digital Printing",
      quantity: "1 Unit",
    },
    care_instructions: [
      "Be careful of the sharp edges.",
      "Dust regularly with a soft, dry cloth.",
      "Avoid hanging the poster in areas with high humidity, such as bathrooms or kitchens.",
    ],
    description: `Elevate your space with the sleek sophistication of our A3 Metal Poster. Expertly crafted from durable metal with a contemporary matte finish, this poster brings a touch of modern elegance to any room. This product utilizes the latest digital printing technology to showcase vibrant, high-definition artwork that is aesthetically pleasing and inspiring. Each poster is designed to stand the test of time, resistant to fading and wear, ensuring your walls remain a focal point of style and creativity. Our metal posters are an effortless way to add a dash of durable artistry to your decor. Whether it's for your home office or as a thoughtful gift, our metal poster is a unique statement piece that combines aesthetic appeal with long-lasting quality.`,
  },
  {
    id: "prod_pt077_cu0002",
    title: "Customised Product Puzzle",
    handle: "customised-product-puzzle",
    name: "puzzle",
    features: {
      material: "Cardboard",
      size: "A3",
      finish: "Matte",
      printing_technique: "Sublimation",
      quantity: "1 Unit",
      pieces: "300",
    },
    care_instructions: [
      "Keep the puzzle away from moisture or humidity.",
      "Avoid using abrasive cleaners or solvents that could damage the coating.",
      "Be gentle to avoid scratching the surface.",
    ],
    description: `Looking for entertainment that’s a little more engaging than yesterday’s memes? Dive into your customised Cardboard Jigsaw Puzzles—300 pieces of pure brain-teasing fun. With eye-catching designs, these puzzles are here to entertain, frustrate, and make you feel like a genius—all in one go. Whether you’re a newbie or a seasoned puzzler, we’ve got your next favourite pastime right here. But wait, there’s more! Once you’ve mastered the puzzle (and tested everyone’s patience), use the included clear glue to turn your accomplishment into a stylish piece of wall art. Family-friendly? Absolutely. These puzzles bring everyone together faster than a group text about free pizza. Versatile decor? Definitely. It’s like those family photos your mom loves to display, but this time, you would want to show it off. Warning: May cause sudden excitement over finding corner pieces. Side effects include developing a love-hate relationship with edge pieces and seeing puzzle shapes in your breakfast cereal.`,
  },
  ...iphoneProducts,
];
/**
 * Gets a product by its handle.
 *
 * @param {Handle} handle - The handle (slug) of the product.
 * @returns {Product | undefined} The product with the given handle, or undefined if not found.
 */
export function getProductByHandle(handle) {
  return productData.find((product) => product.handle === handle);
}

export const products = [
  {
    name: "T-Shirt",
    slug: "/customised-t-shirt",
    image: "/realistic-products/Tshirt.jpg",
    size_guide: "/size-charts/mens_tshirt.png",
  },
  {
    name: "iPhone Case",
    slug: "/customised-iphone-14-cover",
    image: "/realistic-products/Phone-Case.jpg",
    size_guide: null,
  },
  {
    name: "Hoodie",
    slug: "/customised-hoodie",
    image: "/realistic-products/Hoodie.jpg",
    size_guide: "/size-charts/mens_hoodie.png",
  },
  {
    name: "Oversized T-Shirt",
    slug: "/customised-oversized-t-shirt",
    image: "/realistic-products/Oversized-Tshirt.jpg",
    size_guide: "/size-charts/mens_oversized_tshirt.png",
  },
  {
    name: "Sweatshirt",
    slug: "/customised-sweatshirt",
    image: "/realistic-products/Sweatshirt.jpg",
    size_guide: "/size-charts/mens_sweatshirt.png",
  },
  {
    name: "Tote Bag",
    slug: "/customised-casual-tote-bag",
    image: "/realistic-products/Tote-Bag.jpg",
    size_guide: null,
  },
  {
    name: "Puzzle",
    slug: "/customised-product-puzzle",
    image: "/realistic-products/Puzzle.jpg",
    size_guide: null,
  },
  {
    name: "Metal Poster",
    slug: "/customised-metal-poster",
    image: "/realistic-products/A3-Metallic-Poster.jpg",
    size_guide: null,
  },
];

export function getImageBySlug(slug) {
  const product = products.find((product) => product.slug === `/${slug}`);
  return product ? product.image : undefined;
}

export function getSizeGuideBySlug(slug) {
  const product = products.find((product) => product.slug === `/${slug}`);
  return product ? product.size_guide : undefined;
}

export const tagCoverList = [
  {
    tag: "Cubism",
    cover: "/ai/styles/Cubism.png",
  },
  {
    tag: "Anime",
    cover: "/ai/styles/Anime_style.png",
  },
  {
    tag: "Neon colours",
    cover: "/ai/styles/Neon_colours.png",
  },
  {
    tag: "Pixar",
    cover: "/ai/styles/Pixar_style.png",
  },
  {
    tag: "Art Nouveau",
    cover: "/ai/styles/Art_Nouveau.png",
  },
  {
    tag: "Kawaii",
    cover: "/ai/styles/Kawaii.png",
  },
];
